import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import { user } from '../services/user';

import img1 from '../dist/images/about-1.jpg';
import img2 from '../dist/images/about-2.jpg';

class Awards extends React.Component {

    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
    }
    render() {
        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section" id="page-about">
                        <div className="container">
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-4">
                                    <h2 className="title">We create products by today's standards.</h2>
                                    <p className="subtitle description">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the
                                        standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry.
						</p>
                                    <p className="subtitle description">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the
                                        standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry.
						</p>
                                </div>
                                <div className="col-lg-8">
                                    <div className="image">
                                        <img src={img1} className="img-fluid" alt="" />

                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-5">
                                <div className="col-lg-8">
                                    <div className="image">
                                        <img src={img2} className="img-fluid" alt="" />

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <h2 className="title">Make it blend in or stand out.</h2>
                                    <p className="subtitle description">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the
                                        standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry.
						</p>
                                    <p className="subtitle description">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                        been the
                                        standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry.
						</p>
                                </div>
                            </div>


                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Awards;