import React from 'react';
import { toast } from 'react-toastify';
import { user } from '../services/user';
import axios from 'axios';

import Loader from "react-loader-spinner";
import Footer from '../components/footer';
import Header from '../components/header';
import { common } from '../services/common';


class Product extends React.Component {
    state = {
        product: null,
        quantity: 1,
        wishlistLoader: false,
        discounts: [],
        footer: ''
    }



    componentDidMount() {
        window.scrollTo(0, 0);
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
        let product = JSON.parse(localStorage.getItem('product'));
        this.setState({ product }, () => this.getDiscount());
        this.getFooter();

    }

    incrementQuantity() {
        if (this.state.product.stock > this.state.quantity) {
            let quantity = this.state.quantity + 1;
            this.setState({ quantity: quantity })
        }
    }

    decreaseQuantity() {
        let quantity = this.state.quantity;
        if (quantity > 1) {
            this.setState({ quantity: quantity - 1 })
        }
    }

    getFooter() {
        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/footer/fetch`, {}, { headers })
            .then(res => {
                res = res.data;
                if (res.data.length) {
                    let footer = res.data[0].txt;
                    this.setState({ footer });
                }
            })
    }

    addToWishlist() {
        if (!user._token) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            customer: user._details._id,
            product: this.state.product._id,
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ wishlistLoader: true });
        axios.post(`${common._baseURL}api/wishlist/create`, params, { headers })
            .then(res => {
                this.setState({ wishlistLoader: false });
                res = res.data;
                console.log(res);
                if (res.success) {
                    toast.success(res.message);
                    user._wishlist = res.data;
                    localStorage.setItem('wishlist', JSON.stringify(user._wishlist));
                } else {
                    toast.error(res.message);
                }
            })
    }


    addToCart() {
        if(!this.state.quantity){
            toast.error("Please enter valid units!");
            return;
        }
        if (!user._token) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            customer: user._details._id,
            product: this.state.product._id,
            quantity: this.state.quantity,
            productName: this.state.product.name
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/cart/add`, params, { headers })
            .then(res => {
                this.setState({ loader: false });

                res = res.data;
                console.log(res);
                if (res.success && res.data.length) {
                    toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    user._cart = res.data;
                    let product = user._cart.find(cart => cart.product._id === this.state.product._id).product;
                    this.setState({ product });
                    localStorage.setItem('product', JSON.stringify(product));
                    localStorage.setItem('cart', JSON.stringify(user._cart));
                } else {
                    toast.error(res.message);
                }
            })

    }

    getDiscount() {
        const params = {
            categories: this.state.product.categories.map(cat => cat._id)
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/discount/by-categories`, params, { headers })
            .then(res => {
                console.log(res.data);
                this.setState({ discounts: res.data.data });
            }, err => {
                console.log(err);
            });

    }

    setQuantity(event) {
        let value = 0;;
        try {
            value = parseInt(event.target.value);
        } catch (e) {
            value = 0;
        }
        if(value < 0){
            value = 1;
        }else if(value > this.state.product.stock){
            value = this.state.product.stock
        }
        this.setState({ quantity: value });
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const baseUrl = common._baseURL;
        const { product, quantity } = this.state;
        return (
            <div>
                <Header />
                {product &&
                    <section className="section single-product-wrapper">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="product-images">
                                        <figure className="product-thumbnail">
                                            <div className="bck-btn" onClick={() => this.goBack()}>
                                                <i className="bx bx-arrow-back"></i>
                                                <span>Back</span>
                                            </div>
                                            <a data-fancybox="images">
                                                <img src={baseUrl + product.images[0]} className="img-fluid" alt={product.name} />
                                            </a>
                                        </figure>
                                        {this.state.footer ? <div className="description">{this.state.footer}</div>
                                            : ''}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-offset-1">
                                    <div className="product-details">

                                        <div className="product-title">
                                            <h3 className="product-name">{product.strain} - </h3>
                                            {/* <hr className="mt-2" /> */}
                                            <h5>{product.grower}</h5>
                                        </div>
                                        {/* <div className="text-center">
                                            <h2>{product.strain} - </h2>
                                        </div>

                                        <div className="text-center">
                                            <h5>{product.grower}</h5>
                                        </div> */}
                                        <div className="sub-categories">
                                            <div className="row">
                                                <div className="col-xs-6 col-sm-6 col-md-3 p-0 text-center">
                                                    <div className="sub-category" >
                                                        <span>THC: {product.thc || 0}%</span>
                                                    </div>
                                                </div>
                                                <div className="col-xs-6 col-sm-6 col-md-3 p-0 text-center">
                                                    <div className="sub-category" >
                                                        <span>CBD: {product.cbd || 0}%</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-3 p-0 text-center">
                                                    <div className="sub-category" >
                                                        <span>CBGa: {product.cbga || 0}%</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-3 p-0 text-center">
                                                    <div className="sub-category" >
                                                        <span>Terps: {product.terps || 0}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="description" dangerouslySetInnerHTML={{ __html: product.description }}>
                                        </div>

                                        


                                       
                                        <p className="price">
                                            <del>
                                                {product.price.max != product.price.sale ? <span className="amount">${product.price.max}</span> : ''}
                                            </del>
                                            <ins>
                                                <span className="amount">${product.price.sale}</span>
                                            </ins>
                                        </p>
                                        <span style={{ width: "20%" }}>In stock: {product.stock} available</span>
                                        <br />

                                        <form className="inputs-border">
                                            <div className="form-group d-flex">
                                                <div className="quantity">
                                                    <input type="button" value="+" className="plus" onClick={() => this.incrementQuantity()} />
                                                    <input type="number" step="1" max={product.stock} min="1" value={quantity} onChange={($event) => this.setQuantity($event)} title="Qty" className="qty"
                                                        size="4" />
                                                    <input type="button" value="-" className="minus" onClick={() => this.decreaseQuantity()} />
                                                </div>

                                                <button type="button" className="btn btn-primary" onClick={() => this.addToCart()} style={{ minWidth: '170px' }}>
                                                    <i className="lil-add_shopping_cart"></i>
                                                    {this.state.loader ? <Loader type="Puff" color="#00BFFF" height={20} width={20} /> : 'Add to cart'}
                                                </button>

                                                {/* <button type="button" className="btn btn-primary" onClick={() => this.addToWishlist()} style={{ minWidth: '170px', marginLeft: '15px' }}>
                                                    <i className="lil-add_shopping_cart"></i>
                                                    {this.state.wishlistLoader ? <Loader type="Puff" color="#00BFFF" height={20} width={20} /> : 'Add to wishlist'}
                                                </button> */}
                                            </div>
                                        </form>
                                        {this.state.discounts.length ?
                                            <div>
                                                <h4>Prices</h4>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Units</th>
                                                            <th>Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.discounts.map((discount, index) => {
                                                            return <tr key={discount._id}>
                                                                <td>#{index + 1}</td>
                                                                <td>{discount.startingUnit}-{discount.endingUnit}</td>
                                                                <td>${product.price.sale - discount.discount} / Unit</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            : ''}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>

                }
                <Footer />
            </div>
        )
    }
}

export default Product;
