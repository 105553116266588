import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";
import { user } from '../services/user';
import { common } from '../services/common';
import { useParams } from "react-router-dom";
import Footer from '../components/footer';
import Header from '../components/header';

class Reset extends React.Component {
    state = {
        loader: false,
        login: {
            password: '',
            confirmPassword: ''
        },
        loginErrors: {
            password: '',
            formValid: true
        },
        token: '',
        isValid: false
    }

    componentDidMount() {
        const { token } = this.props.match.params;
        console.log('token:', token);
        if (!token) {
            this.props.history.push('/home');
            return;
        }
        this.setState({ token: token }, () => this.validateToken());
    }

    loginFormValidator() {
        let login = this.state.login;
        let errors = {
            password: '',
            formValid: true
        };


        //Password
        if (!login.password) {
            errors.formValid = false;
            errors.password = "Password can not be empty";
        }

        //Confirm Password
        if (!login.confirmPassword) {
            errors.formValid = false;
            errors.password = "Confirm password can not be empty";
        }


        if (login.password && login.password.length < 7) {
            errors.formValid = false;
            errors.password = "Password should be at least 8 characters long";
        } else if (login.confirmPassword !== login.password) {
            errors.formValid = false;
            errors.password = "Password & Confirm password should be same";
        }

        this.setState({ loginErrors: errors });
    }


    setLoginValues(field, event) {
        let login = this.state.login;
        login[field] = event.target.value;
        this.setState({ login });
        this.loginFormValidator();
    }

    validateToken() {
        const params = {
            token: this.state.token
        };
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/forgot/validate`, params)
            .then(res => {
                console.log('res', res);
                this.setState({ loader: false });
                if (!res.data.success) {
                    toast.error(res.data.message);
                    this.props.history.push('/');
                    return;
                }
                // toast.success(res.data.message);
                this.setState({isValid:true});
            })
            .catch(err => {
                this.setState({ loader: false });
                toast.error('Something went wrong !')
            })
    }


    login() {
        this.loginFormValidator();
        if (!this.state.loginErrors.formValid) {
            toast.error('Please provide correct credentials!');
            return;
        }

        const params = {
            password: this.state.login.password,
            confirmPassword: this.state.login.confirmPassword,
            token: this.state.token
        };
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/forgot/reset`, params)
            .then(res => {
                this.setState({ loader: false });
                let data = res.data.data;
                if (!res.data.success) {
                    toast.error(res.data.message)
                    return;
                }
                toast.success(res.data.message)
                this.props.history.push('/');
            })
            .catch(err => {
                this.setState({ loader: false });
                toast.error('Something went wrong !')
            })
    }


    render() {
        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section" id="page-login">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6" style={{ margin: 'auto' }}>
                                    <div className="box">
                                        <h2 className="mb-4">Reset Password</h2>
                                        <form className="login-form inputs-border inputs-bg">
                                            <div className="form-group">
                                                <div className="required">
                                                    <input type="password" onChange={(e) => this.setLoginValues('password', e)} className="form-control" placeholder="Password" />
                                                    <span className="validation-error">{this.state.loginErrors.password}</span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="required">
                                                    <input type="password" onChange={(e) => this.setLoginValues('confirmPassword', e)} className="form-control" placeholder="Confirm Password" />
                                                </div>
                                            </div>

                                            <div className="form-group text-right">
                                                <a >
                                                    <button type="button"
                                                        className="btn btn-primary" onClick={() => this.login()}>
                                                        {this.state.loader ? <Loader type="Puff" color="#00BFFF" height={25} width={25} /> : 'Reset'}
                                                    </button>
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Reset;
