import React from 'react';
import { user } from '../services/user';

import Footer from '../components/footer';
import Header from '../components/header';

class WhereToBuy extends React.Component {
    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
    }
    render() {
        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section pb-0" id="contact-map">
                        <div className="container">
                            <div className="row">
                                <div className="map-wrapper col-sm-12">
                                    {/* <div id="map" data-lat="40.9803480" data-long="28.7270580" data-title="Sham Inc."
                                        data-subtitle="Istanbul, Turkey"></div> */}
                                    <div dangerouslySetInnerHTML={{ __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.05173467296!2d28.724869315413265!3d40.980347979303694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDDCsDU4JzQ5LjMiTiAyOMKwNDMnMzcuNCJF!5e0!3m2!1sen!2sin!4v1612486980645!5m2!1sen!2sin" width="100%" height="450" frameBorder="0" style="border:0;" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>' }}   ></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default WhereToBuy;