import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import Home from './pages/home';
import './App.css';
import Awards from './pages/awards';
import OurProducts from './pages/our-products';
import WhereToBuy from './pages/where-to-buy';
import Login from './pages/login';
import Products from './pages/products';
import Product from './pages/product';
import Cart from './pages/cart';
import WishList from './pages/wishlist';
import LoginSignup from './pages/login-signup';
import Orders from './pages/orders';
import Order from './pages/order';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reset from './pages/reset';
toast.configure({
  limit: 1
});

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            {/* <Route exact path='/' component={Home}></Route> */}
            <Route exact path='/' component={Products}></Route>
            <Route exact path='/awards' component={Awards}></Route>
            <Route exact path='/our-products' component={OurProducts}></Route>
            <Route exact path='/where-to-buy' component={WhereToBuy}></Route>
            <Route exact path='/login' component={Login}></Route>
            <Route exact path='/products' component={Products}></Route>
            <Route exact path='/product' component={Product}></Route>
            <Route exact path='/cart' component={Cart}></Route>
            <Route exact path='/wishlist' component={WishList}></Route>
            <Route exact path='/orders' component={Orders}></Route>
            <Route exact path='/order' component={Order}></Route>
            <Route exact path='/login-signup' component={LoginSignup}></Route>
            <Route exact path='/reset/:token' component={Reset}></Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App; 
