import React from 'react';
import { Link } from 'react-router-dom';
import { user } from '../services/user';
import logo from '../dist/images/logo/logo.png';
import { toast } from 'react-toastify';
import * as moment from 'moment';
import { common } from '../services/common';

import axios from 'axios';
class Header extends React.Component {

    state = {
        cart: [],
        time: '',
        intervalId: ''
    }

    componentDidMount() {
        this.getCartProducts();
        this.handleInterval();

    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    handleInterval() {
        const intervalId = setInterval(() => {
            this.setState({ cart: user._cart });
            let text = '';
            if (user._cart.length) {
                let cartTime = user._cart[0].expiredAt;
                let a = moment(new Date().toISOString());
                let b = moment(cartTime)
                text = 'Your cart will be held for: ';
                let min = b.diff(a, 'minutes');
                let sec = b.diff(a, 'seconds') % 60;
                if (min > 0) {
                    text += min + ' Min ' + sec + ' Sec';
                } else if (min == 0 && sec > 0) {
                    text += sec + ' Seconds';
                } else {
                    text = '';
                    user._cart = [];
                    localStorage.removeItem('cart');
                    this.setState({ cart: user._cart });
                    // this.getCartProducts();
                }
                // console.log('_______', a.diff(b, 'seconds'));
            }
            this.setState({ time: text });
        }, 1000);
        this.setState({ intervalId });
    }

    getCartProducts() {
        if (!user._details) {
            return;
        }

        const params = {
            query: {
                customer: user._details._id,
            }
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/cart/fetch`, params, { headers })
            .then(res => {
                res = res.data;
                console.log(res);
                if (res.success) {
                    user._cart = res.data;
                    this.setState({ products: user._cart })
                    localStorage.setItem('cart', JSON.stringify(user._cart));
                }
            })

    }

    logout() {
        localStorage.clear();
        sessionStorage.clear();
        user._cart = [];
        user._details = null;
        user._token = '';
        user._passwordVerify = false;
        user._cart = [];
        user._wishlist = [];

    }

    render() {
        return (
            <div className="nav-container fixed-top nav-sticky">
                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="Home.html">
                            <img src={logo} alt="Sham Theme" />
                        </a>
                        {user._passwordVerify &&
                            <ul className="nav navbar-icons ml-auto order-lg-2">
                                <li className="nav-item mr-3 shopping-cart">
                                    <Link to="/cart" className="li-icon" title="Cart">
                                        <i className="bx bx-shopping-bag"></i>
                                        <span className="badge badge-secondary rounded-circle">{this.state.cart.length}</span>
                                    </Link>
                                </li>
                                {this.state.time && <li className="nav-item mr-3 shopping-cart">
                                    <span>{this.state.time}</span>
                                </li>
                                }
                                {/* <li className="nav-item mr-3 whishlist">
                                    <Link to="/wishlist" className="li-icon" title="Wishlist">
                                        <i className="bx bx-heart"></i>
                                    </Link>
                                </li> */}

                                {!user._details && <li className="nav-item mr-3 whishlist">
                                    <Link to="/login-signup" className="li-icon" title="Login">
                                        <i className="bx bx-exit"></i>
                                    </Link>
                                </li>
                                }
                                {/* {user._details && <li className="nav-item mr-3 whishlist">
                                    <Link to="/" onClick={() => this.logout()} className="li-icon" title="Logout">
                                        <i className="bx bx-user-x"></i>
                                    </Link>
                                </li>
                                } */}
                            </ul>
                        }
                        <ul className="nav navbar-icons ml-auto order-lg-2">
                            <li>{user._passwordVerify && <Link className="btn btn-primary" to="/products">Products</Link>}
                                {/* {!user._passwordVerify && <Link className="btn btn-primary" to="/login">Dispensary Sign-In</Link>} */}
                            </li>
                        </ul>

                        <button className="navbar-toggler ml-0 ml-sm-3 order-lg-3" type="button" data-toggle="collapse"
                            data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div id="navbar" className="collapse navbar-collapse mr-auto">
                            {user._passwordVerify ?
                                <ul className="navbar-nav mr-auto">
                                    {/* <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li> */}
                                    {/* <li className="nav-item"><Link className="nav-link" to="/awards">Awards</Link></li> */}
                                    {/* <li className="nav-item"><Link className="nav-link" to="/our-products">Our Products</Link></li> */}
                                    {/* <li className="nav-item"><Link className="nav-link" to="/where-to-buy">Where to Buy</Link></li> */}
                                    <li className="nav-item"><a href="https://drive.google.com/drive/folders/1x8gmaX2CruIEsSYJ0IMY3Tp5bLSCHOd3?usp=sharing" target="_blank" className="nav-link" >Test Results</a></li>
                                    <li className="nav-item"><a href="https://drive.google.com/drive/folders/18f64b2LNv9w1rzKBbna0y1vAm1hWNHxF" className="nav-link" target="_blank">Licenses</a></li>
                                    <li className="nav-item"><Link className="nav-link" to="/orders">Your Orders</Link></li>
                                </ul>
                                : ''}
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Header;