import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import Footer from '../components/footer';
import Header from '../components/header';
import { user } from '../services/user';
import { common } from '../services/common';
import * as moment from 'moment';

import Loader from "react-loader-spinner";


class Orders extends React.Component {
    state = {
        orders: [],
        loader: false
    }

    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }
        this.setState({ orders: user._orders });
        this.getCartProducts();
    }

    componentWillUnmount() {
    }


    getCartProducts() {
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            query: {
                customer: user._details._id,
            }
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/order/fetch`, params, { headers })
            .then(res => {
                res = res.data;
                this.setState({ loader: false });
                console.log(res);
                if (res.success) {
                    user._orders = res.data;
                    this.setState({ orders: user._orders })
                    localStorage.setItem('orders', JSON.stringify(user._orders));
                }
            }, err => {
                console.log(err);
                this.setState({ loader: false });
            })

    }

    showDetails(order) {
        localStorage.setItem('order', JSON.stringify(order));
        this.props.history.push('/order');
    }

    render() {
        const baseUrl = common._baseURL;
        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section" id="page-cart">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    {this.state.loader && <Loader type="Puff" color="#00BFFF" height={50} width={50} />}
                                </div>
                                <div className="col-sm-12">
                                    <div className="table-responsive">
                                        {!this.state.orders.length &&
                                            <div className="empty-cart">No Recent Order Found!</div>
                                        }
                                        {this.state.orders.length ?
                                            <table className="table cart-table">
                                                <thead>
                                                    <tr>
                                                        <th className="product-remove">#</th>
                                                        <th className="product-thumbnail">Order Id</th>
                                                        <th className="product-name">Products</th>
                                                        <th className="product-price">Total</th>
                                                        <th className="product-quantity">Date</th>
                                                        <th className="product-subtotal">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.orders.map((order, index) => (
                                                        <tr key={index} className="item">
                                                            <td className="product-remove">
                                                                <span>{index + 1}</span>
                                                            </td>
                                                            <td className="product-thumbnail">
                                                                <span>#{order._id}</span>
                                                            </td>
                                                            <td className="product-name">
                                                                {order.products.map(product => <span key={product._id}>{product.product.name},</span>)}
                                                            </td>
                                                            <td className="product-price">
                                                                <span className="amount">${order.amount}</span>
                                                            </td>
                                                            <td className="product-quantity">
                                                                {moment(order.createdAt).format("MMM Do YYYY, h:mm a")}
                                                            </td>
                                                            <td className="product-subtotal">
                                                                <button type="button" onClick={() => this.showDetails(order)}
                                                                    className="btn btn-primary">
                                                                    Details
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Orders;
