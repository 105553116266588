import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="copyright text-center">
                    <p>© Copyright 2021. All Rights Reserved.</p>
                </div>
            </footer>
        );
    }
}

export default Footer;