import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import Footer from '../components/footer';
import Header from '../components/header';
import { user } from '../services/user';
import { common } from '../services/common';
import * as moment from 'moment';

import Loader from "react-loader-spinner";


class Order extends React.Component {
    state = {
        order: null,
        loader: false,
        total: 0
    }

    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }
        const order = JSON.parse(localStorage.getItem('order')) || null;
        this.setState({ order: order }, () => this.calculateTotal());
        // this.getCartProducts();

    }

    componentWillUnmount() {
    }

    calculateTotal() {
        console.log('this.state.order', this.state.order);
        let total = this.state.order.products.reduce((sum, order) => {
            sum = sum + order.price.sale * order.quantity;
            return sum;
        }, 0);
        this.setState({ total });
    }


    getCartProducts() {
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            query: {
                customer: user._details._id,
            }
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/order/fetch`, params, { headers })
            .then(res => {
                res = res.data;
                this.setState({ loader: false });
                console.log(res);
                if (res.success) {
                    user._orders = res.data;
                    this.setState({ orders: user._orders })
                    localStorage.setItem('orders', JSON.stringify(user._orders));
                }
            }, err => {
                console.log(err);
                this.setState({ loader: false });
            })

    }


    render() {
        const baseUrl = common._baseURL;
        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section" id="page-cart">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="table-responsive">
                                        {this.state.order ?
                                            <table className="table cart-table">
                                                <thead>
                                                    <tr>
                                                        <th className="product-remove">#</th>
                                                        <th className="product-thumbnail"></th>
                                                        <th className="product-name">Product</th>
                                                        <th className="product-price">Price</th>
                                                        <th className="product-quantity">Quantity</th>
                                                        <th className="product-subtotal">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.order.products.map((product, index) => (
                                                        <tr key={index} className="item">
                                                            <td className="product-remove">
                                                                {index + 1}
                                                            </td>
                                                            <td className="product-thumbnail">
                                                                <a onClick={() => this.showProduct(product.product)}>
                                                                    <img src={baseUrl + product.product.images[0]} className="img-fluid" alt={product.product.name} />
                                                                </a>
                                                            </td>
                                                            <td className="product-name">
                                                                <a onClick={() => this.showProduct(product.product)}>{product.product.name}</a>
                                                            </td>
                                                            <td className="product-price">
                                                                <span className="amount">${product.price.sale}</span>
                                                            </td>
                                                            <td className="product-quantity">
                                                                <span>{product.quantity}</span>
                                                            </td>
                                                            <td className="product-subtotal">
                                                                <span className="amount">${product.price.sale * product.quantity}</span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td colSpan="5">
                                                            <span>&nbsp;</span>
                                                        </td>
                                                        <td className="product-subtotal" >
                                                            <span className="amount">${this.state.total}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Order;
