import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { user } from '../services/user';
import Footer from '../components/footer';
import Header from '../components/header';
import Loader from "react-loader-spinner";
import { common } from '../services/common';


class WishList extends React.Component {
    state = {
        products: JSON.parse(localStorage.getItem('wishlist')) || [],
        cartLoader: false
    }

    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }

        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        this.getCartProducts();
    }

    getCartProducts() {
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            query: {
                customer: user._details._id,
            }
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/wishlist/fetch`, params, { headers })
            .then(res => {
                res = res.data;
                console.log(res);
                if (res.success) {
                    user._wishlist = res.data;
                    this.setState({ products: user._wishlist })
                    localStorage.setItem('wishlist', JSON.stringify(user._wishlist));
                }
            })

    }

    removeItem(id) {
        const params = { id, customer: user._details._id };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/wishlist/remove`, params, { headers })
            .then(res => {
                res = res.data;
                console.log(res);
                if (res.success) {
                    user._wishlist = res.data || [];
                    this.setState({ products: user._wishlist })
                    localStorage.setItem('wishlist', JSON.stringify(user._wishlist));
                }
            })
    }

    showProduct(product) {
        localStorage.setItem('product', JSON.stringify(product));
        this.props.history.push('/product');
    }

    addToCart(product) {
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            customer: user._details._id,
            product: product._id,
            quantity: 1
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ cartLoader: true });
        axios.post(`${common._baseURL}api/cart/add`, params, { headers })
            .then(res => {
                this.setState({ cartLoader: false });

                res = res.data;
                console.log(res);
                if (res.success) {
                    toast.success(res.message);
                    user._cart = res.data;
                    localStorage.setItem('cart', JSON.stringify(user._cart));
                } else {
                    toast.error(res.message);
                }
            })

    }

    render() {
        const { products } = this.state;
        const baseUrl = common._baseURL;

        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section" id="page-wishlist">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="table-responsive">
                                        <table className="table cart-table wishlist-table">
                                            <thead>
                                                <tr>
                                                    <th className="product-remove"></th>
                                                    <th className="product-thumbnail"></th>
                                                    <th className="product-name">Product</th>
                                                    <th className="product-price">Unit Price</th>
                                                    <th className="product-stock-status">Stock Status</th>
                                                    <th className="product-add-to-cart"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products.map((product, index) => (
                                                    <tr key={index} className="item">
                                                        <td className="product-remove">
                                                            <a className="remove" title="Remove this item" onClick={() => this.removeItem(product._id)}>
                                                                <i className="bx bx-x"></i>
                                                            </a>
                                                        </td>
                                                        <td className="product-thumbnail">
                                                            <a onClick={() => this.showProduct(product.product)}>
                                                                <img src={baseUrl + product.product.images[0]} className="img-fluid" alt={product.product.name} />
                                                            </a>
                                                        </td>
                                                        <td className="product-name">
                                                            <a onClick={() => this.showProduct(product.product)}>{product.product.name}</a>
                                                        </td>
                                                        <td className="product-price">
                                                            <span className="amount">${product.product.price.sale}</span>
                                                        </td>
                                                        <td className="product-stock-status">
                                                            {product.product.stock && <span className="wishlist-in-stock">In Stock</span>}
                                                            {!product.product.stock && <span className="wishlist-out-of-stock">Out Of Stock</span>}
                                                        </td>
                                                        <td className="product-add-to-cart text-right">
                                                            <button className="btn btn-primary" type="button" onClick={() => this.addToCart(product.product)}>
                                                                {this.state.cartLoader ? <Loader type="Puff" color="#00BFFF" height={20} width={20} /> : 'Add To Cart'}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default WishList;
