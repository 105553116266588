import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import Footer from '../components/footer';
import Header from '../components/header';
import { user } from '../services/user';
import { common } from '../services/common';
import * as moment from 'moment';

import Loader from "react-loader-spinner";


class Cart extends React.Component {
    state = {
        products: [],
        total: 0,
        intervalId: '',
        toastId: '',
        discounts: [],
        discount: 0,
        deliveryFee: 0,
        deliveryFees: []
    }

    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }
        const cart = user._cart.map(cart => {
            cart.oldQuantity = cart.quantity;
            return cart;
        })
        this.setState({ products: cart }, () => {
            console.log('cart:', this.state.products);
            this.getDiscount()
        });
        this.calculateTotal();
        this.getCartProducts();
        this.handleInterval();
        this.getDeliveryFee();
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    handleInterval() {
        const intervalId = setInterval(() => {
            this.setState({ products: user._cart });
            if (!this.state.products.length) {
                this.setState({ total: 0 });
            }
        }, 1000);
    }

    getCartProducts() {
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            query: {
                customer: user._details._id,
            }
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/cart/fetch`, params, { headers })
            .then(res => {
                res = res.data;
                console.log(res);
                if (res.success) {
                    user._cart = res.data;
                    if (user._cart.length) {
                        let cartTime = user._cart[0].expiredAt;
                        let a = moment(new Date().toISOString());
                        let b = moment(cartTime)
                        let sec = b.diff(a, 'seconds');
                        if (sec <= 0) {
                            user._cart = [];
                            localStorage.removeItem('cart');
                        }
                    }
                    const cart = user._cart.map(cart => {
                        cart.oldQuantity = cart.quantity;
                        return cart;
                    })
                    this.setState({ products: cart })
                    localStorage.setItem('cart', JSON.stringify(user._cart));
                    this.calculateTotal();
                    this.getDiscount();
                    this.calculateDeliveryFee();
                }
            })

    }

    removeItem(id) {
        const params = { id, customer: user._details._id };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/cart/remove`, params, { headers })
            .then(res => {
                res = res.data;
                console.log(res);
                if (res.success) {
                    user._cart = res.data || [];
                    const cart = user._cart.map(cart => {
                        cart.oldQuantity = cart.quantity;
                        return cart;
                    })
                    this.setState({ products: cart })
                    localStorage.setItem('cart', JSON.stringify(user._cart));
                    this.calculateTotal();
                }
                this.calculateDiscount();
            })
    }

    calculateTotal() {
        let total = this.state.products.reduce((sum, cart) => {
            sum = sum + cart.product.price.sale * cart.quantity;
            return sum;
        }, 0);
        this.setState({ total });
    }

    showProduct(product) {
        localStorage.setItem('product', JSON.stringify(product));
        this.props.history.push('/product');
    }

    incrementQuantity(product) {
        if (!product.product.stock) return;

        product.quantity = product.quantity + 1;
        let products = this.state.products.map(p => {
            if (p._id === product._id) {
                p.quantity = product.quantity
            }
            return p;
        })
        localStorage.setItem('cart', JSON.stringify(products));
        this.setState({ products });

        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            id: product._id,
            quantity: product.quantity,
            customer: user._details._id,
            product: product.product._id
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/cart/update`, params, { headers })
            .then(res => {
                this.setState({ loader: false });

                res = res.data;
                console.log(res);
                if (this.state.toastId) {
                    toast.dismiss(this.state.toastId);
                }
                let toastId = '';
                if (res.success) {
                    toastId = toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    user._cart = res.data;
                    const cart = user._cart.map(cart => {
                        cart.oldQuantity = cart.quantity;
                        return cart;
                    })
                    this.setState({ products: cart })
                    localStorage.setItem('cart', JSON.stringify(user._cart));
                    this.calculateTotal();
                } else {
                    toastId = toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                this.calculateDiscount();
                this.setState({ toastId });

            })
    }

    decreaseQuantity(product) {
        let quantity = product.quantity;
        if (quantity > 1) {
            quantity = quantity - 1;
            let products = this.state.products.map(p => {
                if (p._id === product._id) {
                    p.quantity = quantity
                }
                return p;
            })
            localStorage.setItem('cart', JSON.stringify(products));
            this.setState({ products });

            if (!user._details) {
                this.props.history.push('/login-signup');
                return;
            }

            const params = {
                id: product._id,
                quantity: quantity,
                customer: user._details._id,
                product: product.product._id
            };

            const headers = {
                'Content-Type': 'application/json',
                'authorization': user._token
            }
            this.setState({ loader: true });
            axios.post(`${common._baseURL}api/cart/update`, params, { headers })
                .then(res => {
                    this.setState({ loader: false });
                    if (this.state.toastId) {
                        toast.dismiss(this.state.toastId);
                    }
                    res = res.data;
                    console.log(res);
                    let toastId = '';
                    if (res.success) {
                        toastId = toast.success(res.message, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        user._cart = res.data;
                        const cart = user._cart.map(cart => {
                            cart.oldQuantity = cart.quantity;
                            return cart;
                        })
                        this.setState({ products: cart })
                        localStorage.setItem('cart', JSON.stringify(user._cart));
                        this.calculateTotal();
                    } else {
                        toastId = toast.error(res.message, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                    this.calculateDiscount();
                    this.setState({ toastId });
                })
        }
    }

    placeOrder() {
        let amount = 0;
        let products = this.state.products.map(cart => {
            let product = cart.product;
            amount += product.price.sale * cart.quantity;
            return {
                product: product._id,
                quantity: cart.quantity,
                price: {
                    max: product.price.max,
                    sale: product.price.sale
                }
            }
        });
        const params = {
            customer: user._details._id,
            products: products,
            amount: amount,
            discount: this.state.discount,
            deliveryFee: this.state.deliveryFee
        };
        console.log('Params:', params);
        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/order/create`, params, { headers })
            .then(res => {
                this.setState({ loader: false });
                let data = res.data.data;
                if (res.data.success) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                } else {
                    toast.error(res.data.message, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    this.getCartProducts();
                }
                user._cart = [];
                localStorage.setItem('cart', '[]');
                this.props.history.push('/products');
            })
    }

    getDiscount() {
        let categories = [];
        this.state.products.map(cart => {
            let cat = cart.product.categories;
            if (typeof cat[0] === 'string') {
                cat.forEach(c => {
                    if (!categories.includes(c)) {
                        categories.push(c);
                    }
                })
            } else {
                cat = cat.map(cat => cat._id);
                cat.forEach(c => {
                    if (!categories.includes(c)) {
                        categories.push(c);
                    }
                })
            }
        });
        const params = {
            categories: categories
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/discount/by-categories`, params, { headers })
            .then(res => {
                this.setState({ discounts: res.data.data });
                console.log('discount', res.data);
                this.calculateDiscount();
            }, err => {
                console.log(err);
            });

    }

    getDeliveryFee() {
        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        axios.post(`${common._baseURL}api/delivery-fee/fetch`, {}, { headers })
            .then(res => {
                this.setState({ deliveryFees: res.data.data });
                this.calculateDiscount();
            }, err => {
                console.log(err);
            });
    }

    calculateDeliveryFee() {
        let fee = 0;
        let units = this.state.products.reduce((a, b) => a += b.quantity, 0);
        console.log('units', units);
        this.state.deliveryFees.forEach(deliveryFee => {
            if (units <= deliveryFee.unit) {
                fee = deliveryFee.fee;
            }
        });

        console.log('appliedDiscount', fee);
        this.setState({ deliveryFee: fee });
    }

    calculateDiscount() {
        let discount = 0;
        let units = this.state.products.reduce((a, b) => a += b.quantity, 0);
        console.log('units', units);
        let appliedDiscount = null;
        this.state.discounts.forEach(discount => {
            if (units >= discount.startingUnit) {
                appliedDiscount = discount
            }
        });
        if (appliedDiscount) {
            discount = units * appliedDiscount.discount;
            // discount = appliedDiscount.discount;
        }

        console.log('appliedDiscount', appliedDiscount);
        console.log('this.state.discounts', this.state.discounts);
        this.setState({ discount: discount });
    }

    setQuantity(product, event) {
        console.log(this.state)
        let value = 0;;
        try {
            value = parseInt(event.target.value);
        } catch (e) {
            value = 0;
        }
        console.log('xx', product.oldQuantity, product.oldQuantity + product.product.stock, value);
        if (product.oldQuantity + product.product.stock < value) {
            value = product.oldQuantity + product.product.stock;
        } else if (value <= 0) {
            value = 1;
        }
        product.quantity = value;
        this.setState({ products: this.state.products });
    }

    updateQuantity(product) {
        if(!product.quantity){
            toast.error("Please enter valid units!");
            return;
        }
        const params = {
            id: product._id,
            quantity: product.quantity,
            customer: user._details._id,
            product: product.product._id
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/cart/update`, params, { headers })
            .then(res => {
                this.setState({ loader: false });
                if (this.state.toastId) {
                    toast.dismiss(this.state.toastId);
                }
                res = res.data;
                console.log(res);
                let toastId = '';
                if (res.success) {
                    toastId = toast.success(res.message, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    user._cart = res.data;
                    const cart = user._cart.map(cart => {
                        cart.oldQuantity = cart.quantity;
                        return cart;
                    })
                    this.setState({ products: cart })
                    localStorage.setItem('cart', JSON.stringify(user._cart));
                    this.calculateTotal();
                } else {
                    toastId = toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                this.calculateDiscount();
                this.setState({ toastId });
            })
    }

    render() {
        const { products } = this.state;
        const baseUrl = common._baseURL;

        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section" id="page-cart">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="table-responsive">
                                        {!this.state.products.length &&
                                            <div className="empty-cart">Cart is empty!</div>
                                        }
                                        {this.state.products.length ?
                                            <table className="table cart-table">
                                                <thead>
                                                    <tr>
                                                        <th className="product-remove"></th>
                                                        <th className="product-thumbnail"></th>
                                                        <th className="product-name">Product</th>
                                                        <th className="product-price">Price</th>
                                                        <th className="product-quantity">Units</th>
                                                        <th className="product-subtotal">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {products.map((product, index) => (
                                                        <tr key={index} className="item">
                                                            <td className="product-remove">
                                                                <a onClick={() => this.removeItem(product._id)} className="remove" title="Remove this item">
                                                                    <i className="bx bx-x"></i>
                                                                </a>
                                                            </td>
                                                            <td className="product-thumbnail">
                                                                <a onClick={() => this.showProduct(product.product)}>
                                                                    <img src={baseUrl + product.product.images[0]} className="img-fluid" alt={product.product.name} />
                                                                </a>
                                                            </td>
                                                            <td className="product-name">
                                                                <a onClick={() => this.showProduct(product.product)}>{product.product.name}</a>
                                                            </td>
                                                            <td className="product-price">
                                                                <span className="amount">${product.product.price.sale}</span>
                                                            </td>
                                                            <td className="product-quantity">
                                                                {this.state.loader ? <div className="quantity" style={{ 'height': '40px', 'textAlign': 'center', 'paddingTop': '5px' }}><Loader type="Puff" color="#00BFFF" height={30} width={30} /></div> : ''}
                                                                {!this.state.loader ?
                                                                    <div className="quantity">
                                                                        <input type="button" value="+" className="plus" onClick={() => this.incrementQuantity(product)} />
                                                                        <input type="number" step="1" max={product.oldQuantity + product.product.stock} min="1" value={product.quantity} title="Qty" onChange={($event) => this.setQuantity(product, $event)}
                                                                            onBlur={() => this.updateQuantity(product)} className="qty" size="4" />
                                                                        <input type="button" value="-" className="minus" onClick={() => this.decreaseQuantity(product)} />
                                                                    </div>
                                                                    : ''}
                                                                <span style={{ width: "20%" }}>In stock: {product.product.stock} available</span>
                                                            </td>
                                                            <td className="product-subtotal">
                                                                <span className="amount">${(product.product.price.sale * product.quantity) || 0}</span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td colSpan="5" className="text-right jrx-price-heading">
                                                            <span>Sub Total</span>
                                                            <span>Discount</span>
                                                            <span>Delivery Fee</span>
                                                            <span><b>Total</b></span>
                                                        </td>
                                                        <td className="product-subtotal jrx-prices" >
                                                            <span className="amount">${this.state.total}</span>
                                                            <span className="amount">${this.state.discount}</span>
                                                            <span className="amount">${this.state.deliveryFee}</span>
                                                            <span className="amount"><b>${this.state.total - this.state.discount + this.state.deliveryFee}</b></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            : ''}

                                    </div>
                                </div>
                                {this.state.products.length ?
                                    <div className="form-group text-right col-md-12 inputMargin">
                                        <a >
                                            <button type="button" onClick={() => this.placeOrder()}
                                                className="btn btn-primary">
                                                {this.state.loader ? <Loader type="Puff" color="#00BFFF" height={20} width={20} /> : 'Place Order'}
                                            </button>
                                        </a>
                                    </div>
                                    : ''
                                }
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Cart;
