import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";
import { user } from '../services/user';
import { common } from '../services/common';

import Footer from '../components/footer';
import Header from '../components/header';

class LoginSignup extends React.Component {
    state = {
        loader: false,
        login: {
            email: '',
            password: ''
        },
        signup: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            deliveryTime: '',
            bln: '',
            dba: '',
            address: '',
            omma: '',
            obndd: ''
        },
        loginErrors: {
            email: '',
            password: '',
            formValid: true
        },
        signupErrors: {
            formValid: true,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            deliveryTime: '',
            bln: '',
            dba: '',
            address: '',
            omma: '',
            obndd: ''
        },
        isForgot: false
    }

    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
    }

    loginFormValidator() {
        let login = this.state.login;
        let errors = {
            email: '',
            password: '',
            formValid: true
        };

        //Email
        if (!login.email) {
            errors.formValid = false;
            errors.email = "Email can not be empty";
        }

        //Password
        if (!login.password) {
            errors.formValid = false;
            errors.password = "Password can not be empty";
        }

        if (login.email && !this.validateEmail(login.email)) {
            errors.formValid = false;
            errors.email = "Email not valid";
        }

        if (login.password && login.password.length < 3) {
            errors.formValid = false;
            errors.password = "Invalid Password";
        }
        this.setState({ loginErrors: errors });
    }

    SignupFormValidator() {
        let signup = this.state.signup;
        let errors = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            deliveryTime: '',
            bln: '',
            dba: '',
            address: '',
            omma: '',
            obndd: '',
            formValid: true
        };

        if (!signup.firstName) {
            errors.formValid = false;
            errors.firstName = "First name can not be empty";
        }

        if (!signup.lastName) {
            errors.formValid = false;
            errors.lastName = "Last name can not be empty";
        }

        if (!signup.phone) {
            errors.formValid = false;
            errors.phone = "Phone can not be empty";
        }
        if (!signup.deliveryTime) {
            errors.formValid = false;
            errors.deliveryTime = "Preferred delivery times can not be empty";
        }

        if (!signup.bln) {
            errors.formValid = false;
            errors.bln = "Business license name can not be empty";
        }
        if (!signup.dba) {
            errors.formValid = false;
            errors.dba = "DBA can not be empty";
        }
        if (!signup.address) {
            errors.formValid = false;
            errors.address = "Address can not be empty";
        }

        if (!signup.omma) {
            errors.formValid = false;
            errors.omma = "OMMA license can not be empty";
        }
        if (!signup.obndd) {
            errors.formValid = false;
            errors.obndd = "OBNDD license can not be empty";
        }

        //Email
        if (!signup.email) {
            errors.formValid = false;
            errors.email = "Email can not be empty";
        }

        //Password
        if (!signup.password) {
            errors.formValid = false;
            errors.password = "Password can not be empty";
        }

        if (signup.email && !this.validateEmail(signup.email)) {
            errors.formValid = false;
            errors.email = "Email not valid";
        }

        if (signup.password && signup.password.length < 8) {
            errors.formValid = false;
            errors.password = "Password should be at least 8 characters long";
        }
        this.setState({ signupErrors: errors });
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    setLoginValues(field, event) {
        let login = this.state.login;
        login[field] = event.target.value;
        this.setState({ login });
        this.loginFormValidator();
    }

    setSignupValues(field, event) {
        let signup = this.state.signup;
        signup[field] = event.target.value;
        this.setState({ signup });
        this.SignupFormValidator();
    }

    login() {
        this.loginFormValidator();
        if (!this.state.loginErrors.formValid) {
            toast.error('Please provide correct credentials!');
            return;
        }

        const params = {
            email: this.state.login.email,
            password: this.state.login.password
        };
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/customer/login`, params)
            .then(res => {
                this.setState({ loader: false });
                let data = res.data.data;
                if (!data) {
                    toast.error('Invalid credentials!')
                    return;
                }
                user._details = data.details;
                user._token = data.token;
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.details));
                this.props.history.push('/products');
            })
            .catch(err => {
                this.setState({ loader: false });
                toast.error('Something went wrong !')
            })
    }

    signup() {
        this.SignupFormValidator();
        if (!this.state.signupErrors.formValid) {
            toast.error('Please provide correct values for signup!');
            return;
        }
        console.log('State:', this.state);
        const params = {
            firstName: this.state.signup.firstName,
            lastName: this.state.signup.lastName,
            email: this.state.signup.email,
            password: this.state.signup.password,
            phone: this.state.signup.phone,
            deliveryTimes: this.state.signup.deliveryTime,
            businessLicenseName: this.state.signup.bln,
            dba: this.state.signup.dba,
            address: this.state.signup.address,
            ommaLicense: this.state.signup.omma,
            obnddLicence: this.state.signup.obndd
        };
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/customer/signup`, params)
            .then(res => {
                this.setState({ loader: false });
                let data = res.data.data;
                if (!data) {
                    toast.error(res.data.message || 'Invalid credentials!')
                    return;
                }
                user._details = data.details;
                user._token = data.token;
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.details));
                this.props.history.push('/products');
            })
    }

    setForgot() {
        this.setState({ isForgot: !this.state.isForgot });
    }

    forgotValidator() {

    }

    forgotPassword() {
        if (!this.validateEmail(this.state.login.email)) {
            toast.error('Please provide correct email address!');
            return;
        }

        const params = {
            email: this.state.login.email,
            password: this.state.login.password
        };
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/forgot`, params)
            .then(res => {
                this.setState({ loader: false });
                let data = res.data.data;
                if (!res.data.success) {
                    toast.error(res.data.message)
                    return;
                }
                toast.success(res.data.message)
                this.props.history.push('/');
            })
            .catch(err => {
                this.setState({ loader: false });
                toast.error('Something went wrong !')
            })
    }

    render() {
        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section" id="page-login">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="box">
                                        {!this.state.isForgot ? <h2 className="mb-4">Login</h2> : ''}
                                        {this.state.isForgot ? <h2 className="mb-4">Forgot Password</h2> : ''}
                                        <form className="login-form inputs-border inputs-bg">
                                            <div className="form-group">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setLoginValues('email', e)} className="form-control" placeholder="Username or email" />
                                                    <span className="validation-error">{this.state.loginErrors.email}</span>
                                                </div>
                                            </div>
                                            {!this.state.isForgot ? <div className="form-group">
                                                <div className="required">
                                                    <input type="password" onChange={(e) => this.setLoginValues('password', e)} className="form-control" placeholder="Password" />
                                                    <span className="validation-error">{this.state.loginErrors.password}</span>
                                                </div>
                                            </div> : ''}
                                            <div className="form-group d-flex align-items-center justify-content-between">
                                                {!this.state.isForgot ? <a className="effect float-right" onClick={() => this.setForgot()}>Forgot your password?</a> : ''}
                                                {this.state.isForgot ? <a className="effect float-right" onClick={() => this.setForgot()}>Login?</a> : ''}
                                            </div>
                                            <div className="form-group text-right">
                                                <a >
                                                    {!this.state.isForgot ? <button type="button"
                                                        className="btn btn-primary" onClick={() => this.login()}>
                                                        {this.state.loader ? <Loader type="Puff" color="#00BFFF" height={25} width={25} /> : 'Login'}
                                                    </button>
                                                        : ''}
                                                    {this.state.isForgot ? <button type="button"
                                                        className="btn btn-primary" onClick={() => this.forgotPassword()}>
                                                        {this.state.loader ? <Loader type="Puff" color="#00BFFF" height={25} width={25} /> : 'Reset'}
                                                    </button>
                                                        : ''}
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="box">
                                        <h2 className="mb-4">Register</h2>
                                        <form className="row register-form inputs-border inputs-bg">
                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setSignupValues('firstName', e)} className="form-control" placeholder="First Name" />
                                                    <span className="validation-error">{this.state.signupErrors.firstName}</span>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setSignupValues('lastName', e)} className="form-control" placeholder="Last Name" />
                                                    <span className="validation-error">{this.state.signupErrors.lastName}</span>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="email" onChange={(e) => this.setSignupValues('email', e)} className="form-control" placeholder="Email address" />
                                                    <span className="validation-error">{this.state.signupErrors.email}</span>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="password" onChange={(e) => this.setSignupValues('password', e)} className="form-control" placeholder="Password" />
                                                    <span className="validation-error">{this.state.signupErrors.password}</span>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="number" onChange={(e) => this.setSignupValues('phone', e)} className="form-control" placeholder="Phone Number" />
                                                    <span className="validation-error">{this.state.signupErrors.phone}</span>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setSignupValues('deliveryTime', e)} className="form-control" placeholder="Preferred delivery times" />
                                                    <span className="validation-error">{this.state.signupErrors.deliveryTime}</span>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setSignupValues('bln', e)} className="form-control" placeholder="Business license name" />
                                                    <span className="validation-error">{this.state.signupErrors.bln}</span>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setSignupValues('dba', e)} className="form-control" placeholder="DBA" />
                                                    <span className="validation-error">{this.state.signupErrors.dba}</span>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setSignupValues('address', e)} className="form-control" placeholder="Address" />
                                                    <span className="validation-error">{this.state.signupErrors.address}</span>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setSignupValues('omma', e)} className="form-control" placeholder="OMMA license" />
                                                    <span className="validation-error">{this.state.signupErrors.omma}</span>
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6 inputMargin">
                                                <div className="required">
                                                    <input type="text" onChange={(e) => this.setSignupValues('obndd', e)} className="form-control" placeholder="OBNDD license" />
                                                    <span className="validation-error">{this.state.signupErrors.obndd}</span>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <div className="form-group text-right col-md-12 inputMargin">
                                                <a >
                                                    <button type="button" onClick={() => this.signup()}
                                                        className="btn btn-primary">
                                                        {this.state.loader ? <Loader type="Puff" color="#00BFFF" height={25} width={25} /> : 'Register'}
                                                    </button>
                                                </a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default LoginSignup;
