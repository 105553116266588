import React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';
import Slide from '../components/slide';
import { user } from '../services/user';

class Home extends React.Component {
    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
    }
    render() {
        return (
            <div>
                <Header />
                <Slide />
                <section className="section products second-style" id="home-products">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 section-title text-center">
                                <h1><i className="line"></i>PERFECTION IS A PROCESS<i className="line"></i></h1>
                                <p>Our method is simple: premium distilled ice, reverse osmosis triple filtered water, blazing hot heat, and just the right amount of pressure. The end result? We'll let you be the judge of that. </p>
                            </div>
                            <div className="col-sm-12 text-center">
                                <a  className="btn btn-primary">Find a Vendor</a>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default Home;