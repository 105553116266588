import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import image1 from '../dist/images/slides/01.jpg';
import image2 from '../dist/images/slides/02.jpg';
import image3 from '../dist/images/slides/03.jpg';

// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);
class Slide extends React.Component {
    render() {
        return (
            <div className="swiper-slider">
                <Swiper
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}

                >
                    <SwiperSlide>
                        <div className="swiper-slide">
                            <div className="d-flex align-items-center">
                                <img src={image1} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide">
                            <div className="d-flex align-items-center">
                                <img src={image2} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide">
                            <div className="d-flex align-items-center">
                                <img src={image3} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        )
    }
}

export default Slide;