import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";
import Footer from '../components/footer';
import Header from '../components/header';
import { user } from '../services/user';
import { common } from '../services/common';

class Login extends React.Component {
    state = {
        password: '',
        loader: false
    }


    handleChange = event => {
        this.setState({ password: event.target.value });
    }

    handleSubmit = event => {
        event.preventDefault();
        if (!this.state.password) {
            toast.warning('Enter Password!')
            return;
        }

        const params = {
            password: this.state.password
        };
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/password/validate`, params)
            .then(res => {
                this.setState({ loader: false });
                console.log('res.data', res.data);
                let data = res.data.data;
                if (!data) {
                    toast.error('Invalid Password!')
                    return;
                }
                user._passwordVerify = true;
                sessionStorage.setItem('password-verify', user._passwordVerify.toString());
                this.props.history.push('/products');
            })
    }

    render() {
        return (
            <div>
                <Header />
                <div className="page-wrapper">
                    <section className="section" id="page-login" style={{ padding: '130px 0px' }}>
                        <div className="container" style={{ maxWidth: '30%' }}>
                            <form onSubmit={this.handleSubmit} className="login-form inputs-border inputs-bg">
                                <div className="form-group">
                                    <div className="required" style={{ display: '-webkit-inline-box' }}>
                                        <input type="text" className="form-control" placeholder="Password" name="password" onChange={this.handleChange} />
                                        <button type="submit" className="btn btn-primary">
                                            {this.state.loader ? <Loader type="Puff" color="#00BFFF" height={25} width={25} /> : 'Login'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default Login;
