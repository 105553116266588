import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { user } from '../services/user';
import Loader from "react-loader-spinner";
import * as moment from 'moment';

import Footer from '../components/footer';
import Header from '../components/header';
import { common } from '../services/common';



class Products extends React.Component {
    state = {
        products: [],
        categories: [],
        category: null,
        cartLoader: false,
        wishlistLoader: false,
        loader: false
    }



    componentDidMount() {
        if (!user._passwordVerify) {
            this.props.history.push('/login');
            return;
        }
        let categories = JSON.parse(localStorage.getItem('categories')) || [];
        this.setState({ categories });

        let products = JSON.parse(localStorage.getItem('products')) || [];
        this.setState({ products });

        this.getProducts();
        this.getCategories();
    }

    getProducts(categoryId = '') {
        console.log('user:', user);
        const params = {
            query: {
                isDeleted: false,
                isActive: true
            }
        };
        if (categoryId) {
            params.query.categories = categoryId;
        }
        this.setState({ loader: true });
        axios.post(`${common._baseURL}api/product/fetch`, params)
            .then(res => {
                this.setState({ loader: false });
                console.log('res:', res.data);
                let data = res.data.data;
                if (!categoryId) localStorage.setItem('products', JSON.stringify(data));
                this.setState({ products: data });
            })
    }

    getCategories() {
        const params = {};
        axios.post(`${common._baseURL}api/category/fetch`, params)
            .then(res => {
                let data = res.data.data;
                localStorage.setItem('categories', JSON.stringify(data));
                this.setState({ categories: data });
            })
    }

    selectCategory(category) {
        this.setState({ category: category });
        this.getProducts(category ? category._id : null);
    }


    addToCart(product) {
        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            customer: user._details._id,
            product: product._id,
            quantity: 1
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ cartLoader: true });
        axios.post(`${common._baseURL}api/cart/add`, params, { headers })
            .then(res => {
                this.setState({ cartLoader: false });

                res = res.data;
                console.log(res);
                if (res.success) {
                    toast.success(res.message);
                    user._cart = res.data;
                    localStorage.setItem('cart', JSON.stringify(user._cart));
                } else {
                    toast.error(res.message);
                }
            })

    }

    addToWishList(product) {

        if (!user._details) {
            this.props.history.push('/login-signup');
            return;
        }

        const params = {
            customer: user._details._id,
            product: product._id,
        };

        const headers = {
            'Content-Type': 'application/json',
            'authorization': user._token
        }
        this.setState({ wishlistLoader: true });
        axios.post(`${common._baseURL}api/wishlist/create`, params, { headers })
            .then(res => {
                this.setState({ wishlistLoader: false });
                res = res.data;
                console.log(res);
                if (res.success) {
                    toast.success(res.message);
                    user._wishlist = res.data;
                    localStorage.setItem('wishlist', JSON.stringify(user._wishlist));
                } else {
                    toast.error(res.message);
                }
            })
    }

    showProduct(product) {
        localStorage.setItem('product', JSON.stringify(product));
        this.props.history.push('/product');
    }



    render() {
        const baseUrl = common._baseURL;
        return (
            <div>
                <Header />
                <section className="section products second-style">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="products-filter list-unstyled">
                                    <li className={!this.state.category ? 'active' : ''} onClick={() => this.selectCategory(null)}>
                                        <a >All</a>
                                    </li>
                                    {this.state.categories.map((category, index) => (
                                        <li key={index} className={this.state.category && this.state.category._id === category._id ? 'active' : ''}>
                                            <a onClick={() => this.selectCategory(category)}>{category.name}</a>
                                        </li>
                                    ))}
                                    {!this.state.categories.length && <li><Loader type="Puff" color="#00BFFF" height={25} width={25} /></li>}
                                </ul>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-sm-12 text-center">
                                {this.state.loader && <Loader type="Puff" color="#00BFFF" height={50} width={50} />}
                            </div>

                            <div className="col-sm-12">
                                <div className="masonry row">
                                    {!this.state.products.length && !this.state.loader &&
                                        <div className="product col-12 text-center" data-product-id="1">
                                            <span>No Product!</span>
                                        </div>
                                    }
                                    {this.state.products.map((product, index) => {
                                        const d1 = moment();
                                        const d2 = moment(product.updatedAt);
                                        const diffDays = d1.diff(d2, 'days');
                                        if (diffDays > 7 && product.stock <= 0) {
                                            return;
                                        }

                                        return <div key={index} className="product col-lg-3 col-md-6" data-product-id="1">
                                            <div className="inner-product">
                                                {product.stock <= 0 ? <span className="onsale hot">Sold Out</span> : ''}
                                                {product.isSale && product.stock > 0 ? <span className="onsale">Sale!</span> : ''}
                                                {product.isNewArrival && product.stock > 0 ? <span className="onsale new">New!</span> : ''}
                                                {product.isHot && product.stock > 0 ? <span className="onsale hot">Hot!</span> : ''}
                                                <div className="product-thumbnail" onClick={() => this.showProduct(product)}>
                                                    <img src={baseUrl + product.images[0]} className="img-fluid products-img" alt={product.name} />
                                                </div>
                                                <div className="product-details text-center">
                                                    <div className="product-btns">
                                                        <span data-toggle="tooltip" data-placement="top" title="Add To Cart">
                                                            <a className="li-icon add-to-cart" onClick={() => this.addToCart(product)}>
                                                                {this.state.cartLoader ? <Loader type="Puff" color="#00BFFF" height={35} width={35} /> : <i className="bx bxs-cart"></i>}
                                                            </a>
                                                        </span>
                                                        <span data-toggle="tooltip" data-placement="top" title="Add to Favorites">
                                                            <a className="li-icon" onClick={() => this.addToWishList(product)}>
                                                                {this.state.wishlistLoader ? <Loader type="Puff" color="#00BFFF" height={35} width={35} /> : <i className="bx bxs-heart"></i>}
                                                            </a>
                                                        </span>
                                                        <span data-toggle="tooltip" data-placement="top" title="View">
                                                            <a className="li-icon view-details" onClick={() => this.showProduct(product)}>
                                                                <i className="bx bx-search"></i>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 className="product-title">
                                                <a onClick={() => this.showProduct(product)}>{product.name}</a>
                                            </h3>
                                            <div>
                                                <span style={{ width: "20%" }}>In stock: {product.stock} available</span>
                                            </div>
                                            <p className="product-price">
                                                <ins>
                                                    <span className="amount">${product.price.sale}</span>
                                                </ins>
                                                <del>
                                                    {product.price.max != product.price.sale ? <span className="amount">${product.price.max}</span> : ''}
                                                </del>
                                            </p>
                                        </div>
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}

export default Products;
